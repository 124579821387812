import { AxiosRequestConfig, AxiosResponse } from 'axios';
import convert from 'xml-js';
import { useCallback, useContext, useMemo } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { FetchQuery, Privilege, SavedQuery, TEntityName, TStatusConfig, ViewSettings } from 'lib';
import { API_URL } from 'domain/authConfig';
import config from 'config/index';
import { useServerError } from 'providers/ErrorProvider';
import { AccessRights } from 'config/EntityMetadata/common';
import { createAxiosInstance, formatXmlQuery } from 'domain/api/setup';
import { devLog } from 'lib/helpers';

export const baseURL = API_URL + '/api/data/v9.2/';

export type Params<T = Record<string, any>> = {
  url: string;
  query?: FetchQuery;
  method?: 'get' | 'post' | 'patch' | 'delete';
  data?: T;
  params?: Record<string, any>;
  eTag?: string;
  onUploadProgress?: AxiosRequestConfig['onUploadProgress'];
  onDownloadProgress?: AxiosRequestConfig['onDownloadProgress'];
  signal?: AxiosRequestConfig['signal'];
  authorize?: boolean;
};

export const useApi = () => {
  const {
    getToken,
    user: { localAccountId },
    systemuserid,
  } = useContext(AuthContext);

  const addServerError = useServerError();

  const axios = useMemo(() => createAxiosInstance(addServerError), [addServerError]);

  const request = useCallback(
    async <R, T = Record<string, any>>(props: Params<T>, accessToken?: string): Promise<AxiosResponse<R>> => {
      const { url, query, method = 'get', data, eTag, params = {}, authorize = true, ...rest } = props;

      const token = authorize ? accessToken ?? (await getToken()) : undefined;

      return axios.request<R>({
        url,
        method,
        headers: {
          ...(token ? { Authorization: 'Bearer ' + token } : {}),
          Prefer: 'odata.include-annotations="*"',
          ...(eTag ? { 'If-Match': eTag } : {}),
        },
        params: {
          ...params,
          ...(query
            ? {
                fetchXml: formatXmlQuery(query),
              }
            : {}),
        },
        data,
        ...rest,
      } as AxiosRequestConfig);
    },
    [getToken, axios]
  );

  const autocompleteRequest = useCallback(
    <T,>(props: Params<{ value: T[] }>) => request<{ value: T[] }>(props),
    [request]
  );

  const getSystemUserId = useCallback(
    () =>
      request<{ value: [{ systemuserid: string }] }>({
        url: 'systemusers',
        params: {
          $select: 'systemuserid',
          $filter: `azureactivedirectoryobjectid eq '${localAccountId}'`,
        },
      }),
    [localAccountId, request]
  );

  const getJoins = useCallback(
    (names: string[], token?: string) =>
      request<{
        value: {
          ReferencedEntity: string;
          ReferencingEntity: string;
          ReferencingAttribute: string;
        }[];
      }>(
        {
          url: `RelationshipDefinitions/Microsoft.Dynamics.CRM.OneToManyRelationshipMetadata`,
          params: {
            $select: 'ReferencingEntity,ReferencingAttribute,ReferencedEntity',
            $filter: names.map((name) => `ReferencingEntity eq '${name}'`).join(' or '),
          },
        },
        token
      ),
    [request]
  );

  const findOneBy = useCallback(
    (url: string, field: string, by: string, value: string) =>
      request<{ value: Record<string, any>[] }>({
        url,
        params: {
          $select: field,
          $filter: `${by} eq '${value}'`,
        },
      }),
    [request]
  );

  const getSystemViews = useCallback(
    (name: string, systemView?: string) => {
      const entityConfig = Object.keys(config).find((item) => config[item as TEntityName].name === name);
      const viewName = systemView || (config[entityConfig as TEntityName] as any)?.systemView || 'Default';

      return request<{ value: SavedQuery[] }>({
        url: 'savedqueries',
        params: {
          $select: 'savedqueryid,name,isdefault,layoutxml,fetchxml,description',
          $filter: `returnedtypecode eq '${name}' and name eq '${viewName}' and (not endswith(description, '(FUND)'))`,
        },
      });
    },
    [request]
  );

  const getUserViews = useCallback(
    (name: string) =>
      request<{ value: ViewSettings[] }>({
        url: 'bahai_portaluserqueries',
        params: {
          $filter: `bahai_tablelogicalname eq '${name}' and ownerid/ownerid eq ${systemuserid}`,
          $expand: `ownerid`,
        },
      }),
    [request, systemuserid]
  );

  const confirmEmail = useCallback(
    () =>
      request<{ GmailAuthenticationLink: string }>({
        url: `systemusers(${systemuserid})/Microsoft.Dynamics.CRM.bahai_getgmailauthenticationlink`,
        method: 'post',
      }),
    [request, systemuserid]
  );

  const updateOrCreateView = useCallback(
    (data: Partial<ViewSettings>, id?: string) =>
      request({
        url: 'bahai_portaluserqueries' + (id ? `(${id})` : ''),
        method: id ? 'patch' : 'post',
        data,
      }),
    [request]
  );

  const getViewById = useCallback(
    (id: string) =>
      request<ViewSettings>({
        url: `bahai_portaluserqueries(${id})`,
        method: 'get',
      }),
    [request]
  );

  const deleteView = useCallback(
    (id: string) =>
      request({
        url: `bahai_portaluserqueries(${id})`,
        method: 'delete',
      }),
    [request]
  );

  const getBusinessUnitName = useCallback(async () => {
    try {
      const {
        data: { BusinessUnitId },
      } = await request<{ BusinessUnitId: string }>({
        url: `WhoAmI`,
      });
      const {
        data: { name, bahai_name },
      } = await request<{ name: string; bahai_name: string }>({
        url: `businessunits(${BusinessUnitId})`,
      });
      return bahai_name || name;
    } catch (e) {
      return 'Unknown Unit';
    }
  }, [request]);

  const getUserSettings = useCallback(
    () =>
      request<{ value: Array<{ bahai_usersettingsid: string; bahai_body: string }> }>({
        url: `bahai_usersettingses`,
        params: {
          $filter: `ownerid/ownerid eq ${systemuserid}`,
          $expand: `ownerid`,
        },
      }),
    [request, systemuserid]
  );

  const deleteUserSettings = useCallback(
    (id: string) =>
      request({
        url: `bahai_usersettingses(${id})`,
        method: 'delete',
      }),
    [request]
  );

  const addUserSettings = useCallback(
    (bahai_body: string) =>
      request({
        url: `bahai_usersettingses`,
        method: 'post',
        data: { bahai_body },
      }),
    [request]
  );

  const updateUserSettings = useCallback(
    (id: string, bahai_body: string) =>
      request({
        url: `bahai_usersettingses(${id})`,
        method: 'patch',
        data: { bahai_body },
      }),
    [request]
  );

  const exportToExcel = useCallback(
    (savedqueryid: string, FetchXml: string, LayoutXml: string, values = [] as string[]) =>
      request({
        url: 'ExportToExcel',
        method: 'post',
        data: {
          View: {
            '@odata.type': 'Microsoft.Dynamics.CRM.savedquery',
            savedqueryid,
          },
          FetchXml,
          LayoutXml,
          QueryApi: '',
          QueryParameters: {
            Arguments: {
              Count: values.length ? 1 : 0,
              IsReadOnly: true,
              Keys: values.length ? ['selectedRecords'] : [],
              Values: values.length
                ? [
                    {
                      Type: 'System.String',
                      Value: values.join(','),
                    },
                  ]
                : [],
            },
          },
        },
      }),
    [request]
  );

  const getACL = useCallback(
    async (id: string, logicalName: string, OwnershipType: string, ObjectTypeCode: number) => {
      try {
        if (OwnershipType == 'BusinessOwned') {
          const userAccessQuery = convert.js2xml(
            {
              fetch: {
                _attributes: {
                  version: '1.0',
                  'output-format': 'xml-platform',
                  returntotalrecordcount: true,
                  'no-lock': true,
                  distinct: true,
                },
                entity: {
                  _attributes: {
                    name: 'role',
                  },
                  'link-entity': [
                    {
                      _attributes: {
                        name: 'systemuserroles',
                        from: 'roleid',
                        to: 'roleid',
                      },
                      filter: [
                        {
                          _attributes: { type: 'and' },
                          condition: {
                            _attributes: {
                              attribute: 'systemuserid',
                              operator: 'eq',
                              value: systemuserid,
                            },
                          },
                        },
                      ],
                    },
                    {
                      _attributes: {
                        name: 'roleprivileges',
                        from: 'roleid',
                        to: 'parentrootroleid',
                      },
                      'link-entity': {
                        _attributes: {
                          name: 'privilege',
                          from: 'privilegeid',
                          to: 'privilegeid',
                          alias: `privilege`,
                        },
                        attribute: {
                          _attributes: { name: 'accessright' },
                        },
                        'link-entity': {
                          _attributes: {
                            name: 'privilegeobjecttypecodes',
                            from: 'privilegeid',
                            to: 'privilegeid',
                          },
                          filter: [
                            {
                              _attributes: { type: 'and' },
                              condition: {
                                _attributes: {
                                  attribute: 'objecttypecode',
                                  operator: 'eq',
                                  value: ObjectTypeCode,
                                },
                              },
                            },
                          ],
                        },
                      },
                    },
                  ],
                },
              },
            },
            {
              compact: true,
              attributeValueFn: (value) => {
                return value
                  .replace(/&(?!quot;)/g, '&amp;')
                  .replace(/</g, '&lt;')
                  .replace(/</g, '&lt;')
                  .replace(/'/g, '&apos;');
              },
            }
          );

          const response = await request<{ value: any[] }>({
            url: 'roles',
            params: {
              fetchXml: encodeURIComponent(userAccessQuery),
            },
          });

          const privilegesResponseMapper = (data: AccessRights): Privilege => {
            switch (data) {
              case AccessRights.ReadAccess:
                return Privilege.Read;
              case AccessRights.WriteAccess:
                return Privilege.Write;
              case AccessRights.AppendAccess:
                return Privilege.Append;
              case AccessRights.AppendToAccess:
                return Privilege.AppendTo;
              case AccessRights.AssignAccess:
                return Privilege.Assign;
              case AccessRights.CreateAccess:
                return Privilege.Create;
              case AccessRights.DeleteAccess:
                return Privilege.Delete;
              case AccessRights.ShareAccess:
                return Privilege.Share;
              case AccessRights.None:
              default:
                return Privilege.None;
            }
          };

          // todo add BU privilages check
          return response.data.value.map((item) =>
            privilegesResponseMapper(item['privilege.accessright'] as AccessRights)
          );
        } else {
          const {
            data: { AccessRights },
          } = await request<{ AccessRights: string }>({
            url: `systemusers(${systemuserid})/Microsoft.Dynamics.CRM.RetrievePrincipalAccess(Target=@Target)`,
            params: {
              '@Target': `{"@odata.id":"${logicalName}(${id})"}`,
            },
          });
          return AccessRights.replaceAll('Access', '').split(', ') as Privilege[];
        }
      } catch (e) {
        devLog(e);
        return [] as Privilege[];
      }
    },
    [request, systemuserid]
  );

  const getRoleName = useCallback(async () => {
    const {
      data: { name },
    } = await request<{ name: string }>({
      url: `systemusers`,
      params: {
        $filter: `systemuserid eq ${systemuserid}`,
        $select: 'fullname,systemuserid,systemuserroles_association&$expand=systemuserroles_association($select=name)',
      },
    });
    return name;
  }, [request, systemuserid]);

  const assign = useCallback(
    (targetLogicalName: string, target: any, assigneeLogicalName: string, assignee: any) =>
      request({
        url: `Assign`,
        data: {
          Target: {
            '@odata.type': `Microsoft.Dynamics.CRM.${targetLogicalName}`,
            ...target,
          },
          Assignee: {
            '@odata.type': `Microsoft.Dynamics.CRM.${assigneeLogicalName}`,
            ...assignee,
          },
        },
        method: 'post',
      }),
    [request]
  );

  const getAvailableStatuses = useCallback(
    async (id: string, logicalName: string) => {
      const data = {
        Record: JSON.stringify({
          Id: id,
          LogicalName: logicalName,
        }),
      };

      const response = await request<{ Statuses: string; Message: string; MessageType: string }>({
        url: `bahai_getavailablestatuses`,
        method: 'post',
        data,
      });
      return {
        Statuses: JSON.parse(response.data.Statuses) as TStatusConfig[],
        Message: response.data.Message,
        MessageType: response.data.MessageType,
      };
    },
    [request]
  );

  return {
    request,
    getRoleName,
    autocompleteRequest,
    getJoins,
    findOneBy,
    getSystemViews,
    getUserViews,
    updateOrCreateView,
    getSystemUserId,
    getUserSettings,
    addUserSettings,
    updateUserSettings,
    deleteUserSettings,
    deleteView,
    getViewById,
    exportToExcel,
    getACL,
    getBusinessUnitName,
    getAvailableStatuses,
    assign,
    confirmEmail,
  };
};
