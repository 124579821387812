import Bowser from 'bowser';
import { useEffect, useContext } from 'react';
import versions from 'config/browsers/browsers.json';
import { useNotifications } from 'providers/NotificationsProvider';
import { useTranslation } from 'react-i18next';
import { devLog } from 'lib/helpers';
import { ScreenContext } from 'providers/ScreenProvider';

export const BrowserChecker = () => {
  const { addWarning } = useNotifications();
  const { t } = useTranslation();
  const { isDescTop } = useContext(ScreenContext);
  const mobileBrowsers = {
    safari: ['iOS', 'macOS'],
    chrome: ['Android'],
  };

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const OSname = browser.getOSName();
    const actualVersion = Number(versions[browser.getBrowserName(true) as keyof typeof versions]);
    const currentVersion = Number(browser.getBrowserVersion().split('.').shift());
    const browserName = browser.getBrowserName(true) as keyof typeof mobileBrowsers;
    const actualMobileBrowser = mobileBrowsers[browserName] && mobileBrowsers[browserName].some((el) => el === OSname);
    const titleNotSupportBrowser = t('Your browser is not supported by ISGP Portal');
    const titleOutdatedVersion = t('Your browser version is outdated');
    const updateMessage = t('For the best ISGP Portal experience, please update your browser to the latest version');
    const contentPrefix = 'For the best ISGP Portal experience, please install one of the recommended browsers:';
    devLog(browser.getBrowserName(), currentVersion, ' / ', actualVersion);

    if (isDescTop) {
      if (!actualVersion) {
        addWarning({
          title: titleNotSupportBrowser,
          content: t(`${contentPrefix} Google Chrome, Firefox, Safari, Edge, Opera`),
        });
      } else if (actualVersion > currentVersion) {
        addWarning({
          title: titleOutdatedVersion,
          content: updateMessage,
        });
      }
    } else {
      if (!actualMobileBrowser) {
        addWarning({
          title: titleNotSupportBrowser,
          content: t(`${contentPrefix} Chrome for Android and Safari for iOS`),
        });
      } else if (actualVersion > currentVersion) {
        addWarning({
          title: titleOutdatedVersion,
          content: updateMessage,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
