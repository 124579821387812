import { addressFields } from './address';

const fields = [
  'bahai_buildingandroomlist',
  'bahai_facilityname',
  'bahai_finishdate',
  'bahai_leadfacilitatorid',
  'bahai_note',
  'bahai_startdate',
  'bahai_typecode',
  'bahai_calendaryear',
  ...addressFields,
] as const;

const readOnly = [
  'bahai_id',
  'bahai_statuscode',
  'bahai_name',
  'bahai_unitid',
  'ownerid',
  'createdon',
  'bahai_yearsoffered',
  'bahai_isgpregionid',
] as const;

export default { fields: fields, columns: [...fields, ...readOnly] };
