import cx from 'classnames';
import { ReactComponent as ErrorIcon } from './icons/error.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';

import classes from 'components/Form/Input/input.module.scss';
import { ReactNode } from 'react';

export const ValidationMessage = ({
  error = '',
  absolute = true,
  warning,
}: {
  error?: ReactNode;
  warning?: boolean;
  absolute?: boolean;
}) => {
  if (!error) return null;

  return (
    <div className={cx(warning ? classes.warning : classes.error, { [classes.absolute]: absolute })}>
      <div style={{ display: 'flex', justifyContent: `center`, gap: '4px', marginTop: `2px` }}>
        {warning ? <WarningIcon /> : <ErrorIcon />}
        {error}
      </div>
    </div>
  );
};
