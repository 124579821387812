import SinglePage from 'components/SinglePage';
import * as config from 'schemas/facilitator';
import { useCallback } from 'react';
import { useArchiveAction, useChangeStatus } from 'schemas/common';
import { useParams } from 'react-router-dom';
import { Action } from 'components/Actions';

export const Facilitator = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { actions: archiveActions, content: archiveContent } = useArchiveAction({
    id,
    entityName: 'facilitator',
    noteField: 'bahai_archivenote',
  });

  const { action: statusAction, content: statusContent } = useChangeStatus({
    id,
    entityName: 'facilitator',
    fieldName: 'bahai_facilitatorstatus',
    online: true,
  });

  const getActions = useCallback(
    (baseActions: Action[]) => baseActions.concat(statusAction, ...archiveActions),
    [archiveActions, statusAction]
  );

  return (
    <>
      {archiveContent}
      {statusContent}
      <SinglePage
        entityName="facilitator"
        displayEdit={true}
        displayRemove={true}
        {...{
          ...config,
          getActions,
        }}
      />
    </>
  );
};
