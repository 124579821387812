import { Trans } from 'react-i18next';
import { ReactNode } from 'react';
import { getWarningImprover, PreSubmit } from 'components/ListPage/components/Form';
import { createPortal } from 'react-dom';
import { Modal } from 'components/Modal';
import { devLog } from 'lib/helpers';
import { AddressValidationTitle } from 'components/AddressValidationTitle';

const warningMessage = <Trans>Invalid value</Trans>;

const warningsMatch: Record<string, string[]> = {
  bahai_zipcode: ['zip', 'address'],
  bahai_stateid: ['state', 'address'],
  bahai_city: ['city', 'address'],
  bahai_addressline: ['direction', 'street'],
};

export const getWarnings = ({
  bahai_addressvalidationmessage: message,
  bahai_international,
  bahai_addressvalidationcode: code,
}: Record<string, string>): Record<string, ReactNode> => {
  if (!code || bahai_international) return {};
  return Object.fromEntries(
    Object.keys(warningsMatch)
      .filter((field) => warningsMatch[field].some((value) => (message || '').toLowerCase().includes(value)))
      .map((field) => [field, warningMessage])
  );
};

export const preSubmit: PreSubmit = async ({ values, initialValues, change, request, setContent }) => {
  if (values.bahai_international) return {};

  if (
    Object.keys(warningsMatch)
      .concat('bahai_addressvalidationmessage', 'bahai_addressvalidationcode')
      .every((field) => values[field] === initialValues[field]) &&
    Number(values.bahai_addressvalidationcode) === 0
  )
    return {};

  const state = values.bahai_stateid
    ? (await request<{ bahai_name: string }>({ url: `bahai_states(${values.bahai_stateid})` })).data.bahai_name
    : '';

  return await new Promise<Record<string, any>>((resolve, reject) => {
    request<{ ValidationResult: string }>({
      url: 'bahai_validateaddress',
      method: 'post',
      data: {
        AddressData: JSON.stringify({
          AddressLine1: values.bahai_addressline,
          AddressLine2: '',
          City: values.bahai_city,
          State: state,
          ZipCode: values.bahai_zipcode,
        }),
      },
    })
      .then(async (resp) => {
        const onCancel = () => {
          change('bahai_addressvalidationmessage', message);
          change('bahai_addressvalidationcode', code);
          setContent(null);
          reject();
        };
        const {
          AddressValidationCode: code,
          AddressValidationMessage: message,
          AddressLine1: bahai_addressline,
          City: bahai_city,
          State: stateName,
          ZipCode: bahai_zipcode,
        } = JSON.parse(resp.data.ValidationResult);

        if (Number(code) === 0) {
          change('bahai_addressvalidationmessage', 'Valid Address');
          change('bahai_addressvalidationcode', 0);
          resolve({ bahai_addressvalidationmessage: 'Valid Address', bahai_addressvalidationcode: 0 });
        }

        //Changes Proposed
        const currentAddress = [values.bahai_addressline, values.bahai_city + ',', state, values.bahai_zipcode].join(
          ' '
        );
        const updatedAddress = [bahai_addressline, bahai_city + ',', state, bahai_zipcode].join(' ');
        if (Number(code) >= 1 && Number(code) <= 99) {
          try {
            const bahai_stateid = stateName
              ? (
                  await request<{ value: Array<{ bahai_stateid: string }> }>({
                    url: `bahai_states`,
                    params: { $filter: `bahai_name eq '${stateName}'` },
                  })
                ).data.value[0].bahai_stateid
              : null;
            setContent(
              createPortal(
                <Modal
                  title={
                    <AddressValidationTitle>
                      {Number(code) < 21 ? (
                        <Trans>Address changes proposed</Trans>
                      ) : (
                        <Trans>Invalid Address changes proposed</Trans>
                      )}
                    </AddressValidationTitle>
                  }
                  controls={[
                    {
                      title: 'Save Changes',
                      onClick: () => {
                        change(
                          'bahai_addressvalidationmessage',
                          `Address changed from ${currentAddress} to ${updatedAddress}`
                        );
                        change('bahai_addressvalidationcode', code);
                        change('bahai_zipcode', bahai_zipcode);
                        change('bahai_addressline', bahai_addressline);
                        change('bahai_stateid', bahai_stateid);
                        change('bahai_city', bahai_city);
                        setContent(null);
                        resolve({
                          bahai_addressvalidationmessage: `Address changed from ${currentAddress} to ${updatedAddress}`,
                          bahai_zipcode,
                          bahai_addressline,
                          bahai_city,
                          bahai_addressvalidationcode: 0,
                        });
                      },
                      role: 'primary',
                    },
                    {
                      title: code < 21 ? 'Keep Original' : 'Save Invalid',
                      onClick: () => {
                        setContent(null);
                        resolve({
                          bahai_addressvalidationmessage: message,
                          bahai_addressvalidationcode: code,
                        });
                      },
                    },
                  ]}
                  onClose={onCancel}
                >
                  <Trans>
                    Original Address <strong>{currentAddress}</strong>
                    will be changed to <strong>{updatedAddress}</strong>
                  </Trans>
                </Modal>,
                document.body
              )
            );
          } catch (e) {
            devLog(e);
          }
        }
        // Address invalid
        if (Number(code) > 99 && Number(code) < 9999) {
          setContent(
            createPortal(
              <Modal
                title={
                  <AddressValidationTitle>
                    <Trans>Invalid Address</Trans>
                  </AddressValidationTitle>
                }
                controls={[
                  {
                    title: 'Save Invalid',
                    onClick: () => {
                      setContent(null);
                      resolve({
                        bahai_addressvalidationmessage: message,
                        bahai_addressvalidationcode: code,
                      });
                    },
                    role: 'secondary',
                  },
                ]}
                onClose={onCancel}
              >
                {message}
              </Modal>,
              document.body
            )
          );
        }
        if (Number(code) === 9999) throw new Error('Service Unavailable');
      })
      .catch((e) => {
        devLog(e);
        // Service Unavailable
        setContent(
          createPortal(
            <Modal
              title={
                <AddressValidationTitle>
                  <Trans>Address Validation service is not available</Trans>
                </AddressValidationTitle>
              }
              controls={[
                {
                  title: 'Save Not Validated',
                  onClick: () => {
                    setContent(null);
                    change('bahai_addressvalidationmessage', 'Address was not validated');
                    change('bahai_addressvalidationcode', 9999);
                    resolve({
                      bahai_addressvalidationmessage: 'Address was not validated',
                      bahai_addressvalidationcode: 9999,
                    });
                  },
                },
              ]}
              onClose={() => {
                change('bahai_addressvalidationmessage', 'Address was not validated');
                change('bahai_addressvalidationcode', 9999);
                setContent(null);
                reject();
              }}
            >
              <Trans>
                Address Validation service is not available at the moment. Please, try later or save not validated
                address
              </Trans>
            </Modal>,
            document.body
          )
        );
      });
  });
};

export const WarningsImprover = getWarningImprover(
  ['bahai_addressvalidationmessage', 'bahai_addressvalidationcode'],
  (values, setWarnings) => {
    if (values.bahai_addressvalidationcode && values.bahai_addressvalidationmessage) {
      setWarnings([values.bahai_addressvalidationmessage]);
    } else {
      setWarnings([]);
    }
  },
  true
);
