import { FC, ReactNode } from 'react';
import { ReactComponent as Icon } from './warning.svg';
import classes from './warning.module.scss';

export const AddressValidationTitle: FC<{ children: ReactNode }> = ({ children }) => (
  <div className={classes.root}>
    <Icon />
    {children}
  </div>
);
