import { useLoader } from 'providers/LoaderProvider';
import { TEntityName } from 'lib';
import { Modal } from 'components/Modal';
import { Field, Form } from 'react-final-form';
import { Input } from 'components/Form';
import * as rules from 'lib/rules';
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRecord } from 'lib/record';
import { useNotifications } from 'providers/NotificationsProvider';
import { devLog } from 'lib/helpers';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { createPortal } from 'react-dom';
import { ReactComponent as ArchiveIcon } from './icons/archive.svg';
import { ReactComponent as UnArchiveIcon } from './icons/unarchive.svg';
import { ReactComponent as StatusIcon } from './icons/status.svg';
import { useMetaData } from 'lib/hooks';
import { createSelect } from 'components/Form/Select';
import classes from './style.module.scss';
import { useApi } from 'domain/api';
import { parseError } from 'lib/errorParser';

const validation = rules.compose([rules.required, rules.maxLength(500)]);

export const RecordStatusIsArchive = <Trans>Record is Archived and the State is Inactive</Trans>;

export const useArchiveAction = ({
  id,
  entityName,
  noteField,
  extraContent,
}: {
  id: string;
  entityName: TEntityName;
  noteField?: string;
  extraContent?: ReactNode;
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const reloadRef = useRef<() => void>();
  const { patch } = useRecord(entityName);
  const [isOpened, setIsOpened] = useState(false);
  const close = useCallback(() => setIsOpened(false), []);
  const { showLoader, hideLoader } = useLoader();
  const { addActionCompleted } = useNotifications();
  const { getLabel } = useMetaData(entityName);

  const label = useMemo(() => (noteField ? getLabel(noteField) : ''), [getLabel, noteField]);

  const archive = useCallback(
    (data: Record<string, any>) => {
      setLoading(true);
      patch({ bahai_state: false, ...data }, id)
        .then(() => {
          setLoading(false);
          addActionCompleted();
          reloadRef.current && reloadRef.current();
          close();
        })
        .finally(() => setLoading(false));
    },
    [patch, id, addActionCompleted, close]
  );

  const unArchive = useCallback(
    (reload: () => void) => {
      showLoader(t('Loading...'));
      patch({ bahai_state: true }, id)
        .then(() => {
          hideLoader();
          addActionCompleted();
          reload();
        })
        .catch((e) => devLog(e))
        .finally(hideLoader);
    },
    [addActionCompleted, hideLoader, id, patch, showLoader, t]
  );

  const actions: Action[] = useMemo(
    () => [
      {
        name: 'archive',
        title: t('Archive'),
        display: ({ data: { bahai_state = true } }) => bahai_state,
        onClick: ({ reload }) => {
          reloadRef.current = reload;
          if (noteField) {
            setIsOpened(true);
          } else {
            archive({});
          }
        },
        Icon: ArchiveIcon,
        type: ActionType.CUSTOM_ACTION,
        allowedDevices: AllowedDevices.All,
        actionContext: ActionContext.SinglePage,
        order: 10,
      },
      {
        name: 'unArchive',
        title: t('Unarchive'),
        display: ({ data: { bahai_state = true } }) => !bahai_state,
        onClick: ({ reload }) => unArchive(reload),
        Icon: UnArchiveIcon,
        type: ActionType.CUSTOM_ACTION,
        allowedDevices: AllowedDevices.All,
        actionContext: ActionContext.SinglePage,
        order: 10,
      },
    ],
    [archive, noteField, t, unArchive]
  );

  const content = createPortal(
    isOpened && (
      <Form
        onSubmit={archive}
        initialValues={{}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal
              title={t('Change to Inactive?')}
              className={classes.dialog}
              portal={false}
              onClose={close}
              loading={loading}
              controls={[
                { title: t('Save'), type: 'submit', role: 'primary' },
                {
                  title: t('Cancel'),
                  onClick: close,
                },
              ]}
            >
              <>
                {extraContent && <div className={classes.content}>{extraContent}</div>}
                <Field
                  name={noteField || ''}
                  component={Input}
                  label={label}
                  validate={validation}
                  required
                  maxLength={500}
                  inputType="area"
                />
              </>
            </Modal>
          </form>
        )}
      />
    ),
    document.body
  );

  return { content, actions };
};

export const useChangeStatus = ({
  id,
  entityName,
  fieldName,
  finalStatuses = [],
  online,
}: {
  id: string;
  entityName: TEntityName;
  fieldName: string;
  finalStatuses?: number[];
  online?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const reloadRef = useRef<() => void>();
  const { patch } = useRecord(entityName);
  const { displayName, getFieldDefinition, logicalName } = useMetaData(entityName);
  const [data, setData] = useState<Record<string, any> | null>(null);
  const { getAvailableStatuses } = useApi();
  const { showLoader, hideLoader } = useLoader();

  const [options, setOptions] = useState(() =>
    getFieldDefinition(fieldName).options.filter((v) => Number(v[0]) !== data?.[fieldName])
  );

  const close = useCallback(() => setData(null), []);
  const { addActionCompleted, addActionFailed, addActionUncompleted } = useNotifications();

  const finalStatusesText = useMemo(() => {
    const record = Object.fromEntries(options);
    return finalStatuses.map((v) => record[String(v)]).join(' or ');
  }, [finalStatuses, options]);

  const apply = useCallback(
    (formData: Record<string, any>) => {
      setLoading(true);
      patch(formData, id, data?.eTag)
        .then(() => {
          setLoading(false);
          addActionCompleted();
          reloadRef.current && reloadRef.current();
          close();
        })
        .catch((e) => addActionFailed(parseError(e)))
        .finally(() => setLoading(false));
    },
    [patch, id, data?.eTag, addActionCompleted, close, addActionFailed]
  );

  const action: Action = useMemo(
    () => ({
      name: 'change_status',
      title: t('Change Status'),
      onClick: ({ reload, selectedItems: [{ [fieldName]: status, bahai_state }] }) => {
        if (bahai_state === false) {
          addActionUncompleted(RecordStatusIsArchive);
        } else if (finalStatuses.includes(Number(status))) {
          addActionUncompleted(
            t('{{ displayName }} Status can`t be changed from {{ finalStatusesText }}', {
              displayName,
              finalStatusesText,
            })
          );
        } else {
          reloadRef.current = reload;
          if (online) {
            showLoader('Loading...');
            getAvailableStatuses(id, logicalName)
              .then((resp) => {
                if (resp.Message) {
                  addActionUncompleted(resp.Message);
                } else {
                  setOptions(resp.Statuses.map(({ DisplayName, StatusCode }) => [String(StatusCode), DisplayName]));
                  setData({ [fieldName]: status });
                }
              })
              .finally(hideLoader);
          } else {
            setData({ [fieldName]: status });
          }
        }
      },
      Icon: StatusIcon,
      type: ActionType.CUSTOM_ACTION,
      allowedDevices: AllowedDevices.All,
      actionContext: ActionContext.SinglePage,
      order: 10,
    }),
    [
      addActionUncompleted,
      displayName,
      fieldName,
      finalStatuses,
      finalStatusesText,
      getAvailableStatuses,
      hideLoader,
      id,
      logicalName,
      online,
      showLoader,
      t,
    ]
  );

  const component = useMemo(() => createSelect(new Map(options)), [options]);

  const content = createPortal(
    !!data && (
      <Form
        onSubmit={apply}
        initialValues={{}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal
              title={t('Change Status')}
              portal={false}
              onClose={close}
              loading={loading}
              controls={[
                { title: t('Save'), type: 'submit', role: 'primary' },
                {
                  title: t('Cancel'),
                  onClick: close,
                },
              ]}
            >
              <Field
                name={fieldName}
                component={component}
                label={t('Status')}
                validate={rules.required}
                required
                isClearable={false}
              />
            </Modal>
          </form>
        )}
      />
    ),
    document.body
  );

  return { content, action };
};
