const addressFields = [
  'bahai_addressline',
  'bahai_city',
  'bahai_stateid',
  'bahai_zipcode',
  'bahai_addressvalidationmessage',
  'bahai_addressvalidationcode',
] as const;

export { addressFields };
