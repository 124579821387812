import { useRecord } from 'lib/record';
import { useCallback, useState, useMemo, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { devLog } from 'lib/helpers';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';

import { ReactComponent as RegisteredIcon } from './icons/changeToRegistered.svg';
import { useNotifications } from 'providers/NotificationsProvider';
import { REGISTERED, INVITED } from 'schemas/person';
import { Loader } from 'components/Loader';
import classes from '../../../components/Modal/modal.module.scss';

export const useRegisteredAction = (id: string) => {
  const { t } = useTranslation();
  const reloadRef = useRef<() => void>();
  const { patch } = useRecord('person');
  const { addActionCompleted, addActionUncompleted } = useNotifications();
  const [loading, setLoading] = useState(false);

  const changeToRegistered = useCallback(
    (id: string) => {
      setLoading(true);
      patch({ bahai_statuscode: REGISTERED }, id)
        .then(() => {
          reloadRef.current && reloadRef.current();
          addActionCompleted();
        })
        .catch((e) => {
          addActionUncompleted(
            JSON.parse(e.response.data.error.message)
              .map((v: any) => v.Message)
              .join(', ')
          );
          devLog(e);
        })
        .finally(() => setLoading(false));
    },
    [patch, addActionCompleted, addActionUncompleted]
  );

  const actions: Action[] = useMemo(
    () => [
      {
        name: 'changeToRegistered',
        title: t('Change To Registered'),
        display: ({ data }) => Number(data.bahai_statuscode) === INVITED,
        onClick: ({ reload }) => {
          reloadRef.current = reload;
          changeToRegistered(id);
        },
        Icon: RegisteredIcon,
        type: ActionType.CUSTOM_ACTION,
        allowedDevices: AllowedDevices.All,
        actionContext: ActionContext.SinglePage,
        order: 11,
      },
    ],
    [t, changeToRegistered, id]
  );

  const content = createPortal(
    loading && (
      <div className={classes.loader}>
        <Loader />
      </div>
    ),
    document.body
  );

  return { actions, content };
};
