import { ReactNode, useCallback, useMemo } from 'react';
import classes from './input.module.scss';
import cx from 'classnames';
import { TInputProps } from 'components/Form/types';
import { ValidationMessage } from 'components/Form/ValidationMessage';
import { Input as BaseInput } from 'components/Input';

const Input = ({
  label,
  meta,
  input: { value = '', ...input },
  className,
  inputType = 'text',
  required,
  readOnly,
  values: _,
  warnings,
  ...props
}: TInputProps & { inputType?: 'text' | 'area' | 'phone'; warnings?: ReactNode }) => {
  const errors: string | undefined = useMemo(() => (meta.touched ? meta.error || meta.submitError : undefined), [meta]);

  const onInputBlur = useCallback(() => {
    if (/^[\s]+$/.test(`${value}`)) {
      input.onChange(undefined);
    } else if (typeof value === 'string') input.onChange(value.trim());
  }, [input, value]);

  return (
    <div className={cx(classes.wrapper, className, { [classes.disabled]: props.disabled })}>
      <label
        className={cx(classes.label, {
          [classes.required]: required,
          [classes.readOnly]: readOnly,
          [classes.disabled]: !!props.disabled,
        })}
        htmlFor={input.name}
      >
        {label}
      </label>
      {readOnly ? (
        <div className={classes.value}>{value || '---'}</div>
      ) : (
        <BaseInput
          className={cx(classes.input, { [classes.hasError]: !!errors, [classes.hasWarning]: !!warnings && !errors })}
          {...input}
          value={Array.isArray(value) ? value.join('') : value}
          {...props}
          onBlur={onInputBlur}
          inputType={inputType}
        />
      )}
      <div className={classes.bottomWrapper}>
        {!readOnly && <ValidationMessage error={errors} absolute={inputType !== 'area'} />}
        {!readOnly && !errors && <ValidationMessage error={warnings} absolute={inputType !== 'area'} warning />}
        {inputType === 'area' && !readOnly && (
          <div className={classes.counter}>
            {value.length} / {props.maxLength || 255}
          </div>
        )}
      </div>
    </div>
  );
};

export { Input };
