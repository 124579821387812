import { ReactComponent as LockIcon } from './icons/lock.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import { ReactComponent as DraftIcon } from './icons/draft.svg';
import { ReactComponent as ClockIcon } from './icons/clock.svg';
import { ReactComponent as ExclamationIcon } from './icons/exclamation.svg';
import { ReactComponent as Danger } from './icons/danger.svg';
import { ReactComponent as Success } from './icons/success.svg';
import { ReactComponent as Custom } from './icons/custom.svg';
import { ReactComponent as Expand } from './icons/expand.svg';
import { ReactComponent as Archive } from './icons/archive.svg';
import classes from './style.module.scss';
import cx from 'classnames';
import { FC, ReactNode, SVGProps, useCallback, useMemo, useState } from 'react';
import { IconButton } from 'components/IconButton';

const icons = {
  lock: <LockIcon />,
  warning: <WarningIcon />,
  info: <InfoIcon />,
  draft: <DraftIcon />,
  clock: <ClockIcon />,
  exclamation: <ExclamationIcon />,
  danger: <Danger />,
  success: <Success />,
  custom: <Custom />,
  archive: <Archive />,
};

export type TopMessage = {
  message: ReactNode;
  type?: 'error' | 'warning' | 'success' | 'info' | 'custom' | 'archive';
  Icon?: keyof typeof icons | FC<SVGProps<any>>;
};

export const TopMessages = ({ messages }: { messages: TopMessage[] }) => {
  const [expanded, setExpanded] = useState(false);

  const toggle = useCallback(() => setExpanded((v) => !v), []);

  const items = useMemo(() => (expanded ? messages : messages.slice(0, 1)), [expanded, messages]);

  return (
    <div className={cx(classes.root, { [classes.long]: messages.length > 1 })}>
      {messages.length > 1 && (
        <IconButton
          onClick={toggle}
          iconOnly
          Icon={Expand}
          className={cx(classes.expand, { [classes.active]: expanded })}
          data-messages={messages.length}
        />
      )}
      <>
        {items.map(({ message, type = 'info', Icon = 'info' }, index) => (
          <div key={`${index}-${type}`} className={cx(classes.message, classes[type])}>
            <div className={classes.lockIcon}>{typeof Icon === 'string' ? icons[Icon] : <Icon />}</div>
            <div className={classes.text}>{message}</div>
          </div>
        ))}
      </>
    </div>
  );
};
