import entities, { SystemFields } from 'config';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { Trans } from 'react-i18next';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import { AreaTooltipComponent, EmailComponent, PhoneComponent, RawComponent } from 'lib/components';
import * as rules from 'lib/rules';
import { FieldValidator } from 'final-form';
import { ReactComponent as InfoIcon } from './info.svg';
import Tooltip from 'components/Tooltip';
import { TLinkEntity } from 'components/ListPage';
import { TopMessage } from 'components/TopMessages';
import { RecordStatusIsArchive } from 'schemas/common';
import { parseDate } from 'lib/adapter';

export const CLEARANCE_APPROVED = '370790000';
export const OTHER_DEGREE = '370790004';

export type Keys =
  | (typeof entities.facilitator.columns)[number]
  | 'bahai_facilitatorid'
  | SystemFields
  | 'state.bahai_stateregionid';

export const defaultMobileColumns: Keys[] = ['bahai_name', 'bahai_email', 'bahai_id'];

export const columns = [...entities.facilitator.columns, 'state.bahai_stateregionid'];

const ENGLISH = '370790000';
const OTHER_LANGUAGE = '370790014';

export const links = {
  state: entities.state.columns,
} as TLinkEntity;

const clearanceLabel = (
  <>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        lineHeight: '20px',
      }}
    >
      <Trans>Clearance</Trans>
      <Tooltip content="Approved to work with minors, Persons under 18 years old.">
        <InfoIcon style={{ marginLeft: '5px' }} />
      </Tooltip>
    </div>
  </>
);

export const config: AdditionalConfigType<Keys> = {
  bahai_id: {
    searchable: true,
    component: ({ data }) => (
      <HistoryLink to={r.facilitator({ id: data.bahai_facilitatorid || 0 })}>{data.bahai_id}</HistoryLink>
    ),
  },
  bahai_name: { searchable: true },
  bahai_firstname: {
    isRequired: true,
    fieldProps: () => ({
      maxLength: 30,
    }),
  },
  bahai_lastname: {
    isRequired: true,
    fieldProps: () => ({
      maxLength: 30,
    }),
  },
  bahai_nickname: { fieldProps: () => ({ maxLength: 30 }) },
  bahai_email: { component: EmailComponent, isRequired: true },
  bahai_phone: {
    isRequired: (values) => !values.bahai_phoneinput,
    component: (props) => (
      <PhoneComponent {...props} name={props.data.bahai_phone ? 'bahai_phone' : 'bahai_phoneinput'} />
    ),
    fieldProps: () => ({
      inputType: 'phone',
    }),
  },
  bahai_phoneinput: { isDisabled: (_, data) => !!data?.id, component: PhoneComponent },
  bahai_profileimage: { hiddenForTable: true, excludeFromListQuery: true },
  bahai_dateofbirth: {
    fieldProps: () => ({ canSelectFutureDate: false }),
    isRequired: true,
  },
  bahai_city: { isRequired: true },
  bahai_stateid: { isRequired: true },
  bahai_addressline: {
    component: AreaTooltipComponent,
    isRequired: true,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      maxLength: 500,
    }),
  },
  bahai_zipcode: { isRequired: true },
  bahai_note: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
  bahai_currentoccupationorfieldofservice: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 500,
    }),
  },
  bahai_priorexperience: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
  bahai_undergraduatefieldofstudy: { fieldProps: () => ({ maxLength: 100 }) },
  bahai_othergraduatedegree: { isRequired: true, fieldProps: () => ({ maxLength: 100 }) },
  bahai_graduatefieldofstudycompleted: { fieldProps: () => ({ maxLength: 100 }) },
  bahai_graduategraduationyear: { fieldProps: () => ({ inputType: 'year' }), component: RawComponent },
  bahai_undergraduategraduationyear: { fieldProps: () => ({ inputType: 'year' }), component: RawComponent },
  bahai_highschoolgraduationyear: { fieldProps: () => ({ inputType: 'year' }), component: RawComponent },
  bahai_otherlanguage: { fieldProps: () => ({ maxLength: 100 }) },
  bahai_clearance: {
    label: clearanceLabel,
    fieldProps: () => ({
      label: clearanceLabel,
    }),
  },
  bahai_renewaldate: {
    isRequired: (values) => String(values.bahai_clearance) === CLEARANCE_APPROVED,
  },
  bahai_graduatedegreecompletedcode: { sortable: false },
  bahai_archivenote: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
    }),
  },
};

export const getContactFields = (data: Record<Keys, any>) => [
  'bahai_email',
  ...((data.bahai_phoneinput ? ['bahai_phoneinput'] : ['bahai_phone']) as Keys[]),
];

export const getCommonFields = (): Keys[] => ['bahai_unitid', 'bahai_isgpregionid', 'ownerid', 'createdon'];
export const getTitleFields = (): Keys[] => ['bahai_firstname', 'bahai_lastname'];
export const getStatusFields = (): Keys[] => ['bahai_facilitatorstatus'];

export const getInitialValues = async () => ({ bahai_fluentlanguage: [ENGLISH] });

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  bahai_email: rules.compose([rules.required, rules.email, rules.maxLength(100)]),
  bahai_phone: rules.compose([
    rules.conditionalRule((values) => !values.bahai_phoneinput, rules.required),
    rules.phone,
  ]),
  bahai_dateofbirth: rules.compose([rules.required, rules.pastDate]),
  bahai_firstname: rules.compose([rules.required, rules.maxLength(30)]),
  bahai_lastname: rules.compose([rules.required, rules.maxLength(30)]),
  bahai_nickname: rules.maxLength(30),
  bahai_city: rules.compose([rules.required, rules.maxLength(30)]),
  bahai_stateid: rules.required,
  bahai_zipcode: rules.compose([rules.required, rules.maxLength(10)]),
  bahai_addressline: rules.compose([rules.required, rules.maxLength(500)]),
  bahai_note: rules.maxLength(2000),
  bahai_currentoccupationorfieldofservice: rules.maxLength(500),
  bahai_priorexperience: rules.maxLength(2000),
  bahai_graduategraduationyear: rules.year,
  bahai_undergraduategraduationyear: rules.year,
  bahai_highschoolgraduationyear: rules.year,
  bahai_otherlanguage: rules.maxLength(100),
  bahai_undergraduatefieldofstudy: rules.maxLength(100),
  bahai_othergraduatedegree: rules.compose([
    rules.conditionalRequired((values) => values.bahai_graduatedegreecompletedcode?.includes(OTHER_DEGREE)),
    rules.maxLength(100),
  ]),
  bahai_graduatefieldofstudycompleted: rules.maxLength(100),
  bahai_renewaldate: rules.compose([
    rules.conditionalRequired((values) => String(values.bahai_clearance) === CLEARANCE_APPROVED),
    rules.futureDateDirtyOnly,
  ]),
};

export const getDetailsConfig: FormConfigGetter<Keys> = (data) => [
  [
    <Trans>Personal Information</Trans>,
    [
      'bahai_firstname',
      'bahai_lastname',
      'bahai_nickname',
      'bahai_dateofbirth',
      'bahai_age',
      'bahai_gendercode',
      'bahai_fluentlanguage',
      ...((data.bahai_fluentlanguage?.includes(OTHER_LANGUAGE) ? ['bahai_otherlanguage'] : []) as Keys[]),
      'bahai_priorexperience',
      'bahai_clearance',
      ...((String(data.bahai_clearance) === CLEARANCE_APPROVED ? ['bahai_renewaldate'] : []) as Keys[]),
      'bahai_currentoccupationorfieldofservice',
      'bahai_archivenote',
      'bahai_note',
      'bahai_addressline',
      'bahai_city',
      'bahai_stateid',
      'bahai_zipcode',
      'state.bahai_stateregionid',
    ],
  ],
  [
    <Trans>Education</Trans>,
    [
      'bahai_highschoolgraduationyear',
      'bahai_undergraduategraduationyear',
      'bahai_undergraduatefieldofstudy',
      'bahai_graduategraduationyear',
      'bahai_graduatefieldofstudycompleted',
      'bahai_graduatedegreecompletedcode',
      ...((data.bahai_graduatedegreecompletedcode?.includes(OTHER_DEGREE)
        ? ['bahai_othergraduatedegree']
        : []) as Keys[]),
    ],
  ],
];

export const getFormConfig: FormConfigGetter<Keys> = (data) => [
  [
    <Trans>Profile Info</Trans>,
    ['bahai_phone', ...((data.bahai_phoneinput ? ['bahai_phoneinput'] : []) as Keys[]), 'bahai_email'],
  ],
  [
    <Trans>Personal Information</Trans>,
    [
      'bahai_firstname',
      'bahai_lastname',
      'bahai_nickname',
      'bahai_dateofbirth',
      'bahai_gendercode',
      'bahai_fluentlanguage',
      ...((data.bahai_fluentlanguage?.includes(OTHER_LANGUAGE) ? ['bahai_otherlanguage'] : []) as Keys[]),
      'bahai_priorexperience',
      'bahai_clearance',
      ...((String(data.bahai_clearance) === CLEARANCE_APPROVED ? ['bahai_renewaldate'] : []) as Keys[]),
      'bahai_currentoccupationorfieldofservice',
      'bahai_note',
      'bahai_addressline',
      'bahai_city',
      'bahai_stateid',
      'bahai_zipcode',
    ],
  ],
  [
    <Trans>Education</Trans>,
    [
      'bahai_highschoolgraduationyear',
      'bahai_undergraduategraduationyear',
      'bahai_undergraduatefieldofstudy',
      'bahai_graduategraduationyear',
      'bahai_graduatefieldofstudycompleted',
      'bahai_graduatedegreecompletedcode',
      ...((data.bahai_graduatedegreecompletedcode?.includes(OTHER_DEGREE)
        ? ['bahai_othergraduatedegree']
        : []) as Keys[]),
    ],
  ],
];

export { getWarnings, preSubmit } from 'lib/addressValidation';
export { WarningsImprover } from 'schemas/person';

export const isActive = (data: Record<Keys, any>) => !!data.bahai_state;
export const isNotEditable = (data: Record<Keys, any>) => (!isActive(data) ? RecordStatusIsArchive : false);

export const getTopMessages = (data: Record<string, any>) => {
  const messages = [] as TopMessage[];
  if (!isActive(data)) messages.push({ message: RecordStatusIsArchive, Icon: `archive` });
  if (
    String(data.bahai_clearance) === CLEARANCE_APPROVED &&
    data.bahai_renewaldate &&
    parseDate(data.bahai_renewaldate, false) < new Date()
  )
    messages.push({
      message: <Trans>Renewal Date for the Clearance has passed. Please renew again</Trans>,
      Icon: 'danger',
      type: 'error',
    });
  return messages;
};
