import SinglePage from 'components/SinglePage';
import * as config from 'schemas/person';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Action } from 'components/Actions';
import { useParams } from 'react-router-dom';
import HistoryLogTab from 'components/HistoryLogTab';
import { useRegisteredAction } from 'schemas/person/components';
import { ApplicationList } from 'pages/ApplicationList';
import { useArchiveAction } from 'schemas/common';

export const Person = () => {
  const { t } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();
  const { actions: archiveActions, content: archiveContent } = useArchiveAction({
    id,
    entityName: 'person',
    noteField: 'bahai_statusnote',
    extraContent: (
      <Trans>
        Please note that Person will be removed from the Session in Seminars with Status = "Planning Phase" and "In
        Progress"
      </Trans>
    ),
  });
  const { actions: registeredActions, content: registeredContent } = useRegisteredAction(id);

  const getActions = useCallback(
    (baseActions: Action[]) => baseActions.concat(...archiveActions, ...registeredActions),
    [archiveActions, registeredActions]
  );

  return (
    <>
      {archiveContent}
      {registeredContent}
      <SinglePage
        entityName="person"
        displayEdit={true}
        displayRemove={true}
        {...config}
        getActions={getActions}
        additionalTabs={[
          {
            label: t('Applications'),
            tab: 'application',
            isDefault: true,
            content: (data: Record<string, any>) => <ApplicationList data={data} />,
          },
          {
            label: t('History Log'),
            tab: 'historylog',
            content: (data: Record<string, any>) => <HistoryLogTab id={id || ''} data={data} />,
          },
        ]}
      />
    </>
  );
};
