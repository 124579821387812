import ListPage, { TListPage } from 'components/ListPage';
import * as config from 'schemas/application';
import { useApi } from 'domain/api';
import { useFormChanges } from 'lib/hooks';
import { useFormState } from 'react-final-form';
import { useRecord } from 'lib/record';
import { useCallback, useEffect, useMemo } from 'react';
import * as applicationConfig from 'schemas/application';
import { ApiFilter } from 'lib';
import { PersonStatusIsArchive } from 'schemas/application';

const PersonFieldsImprover = () => {
  const { request } = useApi();
  const { changes, update } = useFormChanges(['bahai_personid']);
  const { values, initialValues } = useFormState();
  const { getFormValues } = useRecord('person');

  const updateValuesFromPerson = useCallback(async () => {
    const { data } = await request<Partial<Record<string, any>>>({
      url: `bahai_persons(${changes.bahai_personid || values.bahai_personid})`,
    });

    const formValues = getFormValues(data);
    update(Object.fromEntries(config.personColumns.map((key) => [key, formValues[key] ?? null])));
  }, [request, changes.bahai_personid, values.bahai_personid, getFormValues, update]);

  useEffect(() => {
    if (Object.keys(changes).includes('bahai_personid')) {
      if (changes.bahai_personid) {
        updateValuesFromPerson().then();
      }
    }
  }, [changes, request, update, values, initialValues, updateValuesFromPerson]);
  return null;
};

export const ApplicationList = ({ data, ...rest }: { data?: Record<string, any> & Partial<TListPage> }) => {
  const { getFormValues } = useRecord('person');

  const personFormValues = useMemo(() => (data ? getFormValues(data) : {}), [data, getFormValues]);

  const getInitialValues = useCallback(
    async () => ({
      ...(await config.getInitialValues()),
      ...(data
        ? {
            bahai_personid: data.bahai_personid,
            ...Object.fromEntries(applicationConfig.personColumns.map((key) => [key, personFormValues[key]])),
          }
        : {}),
    }),
    [data, personFormValues]
  );

  const hiddenFilters: ApiFilter[] | undefined = useMemo(
    () =>
      data ? [{ condition: [{ operator: 'eq', attribute: 'bahai_personid', value: data.bahai_personid }] }] : undefined,
    [data]
  );

  const isNotCreatable = useMemo(() => (data && !data.bahai_state ? PersonStatusIsArchive : undefined), [data]);

  return (
    <ListPage
      allowDraft
      FormImprover={PersonFieldsImprover}
      entityName="application"
      {...{ ...applicationConfig, getInitialValues, hiddenFilters, isNotCreatable, ...rest }}
      isExportAllowed={true}
    />
  );
};
